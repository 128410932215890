import React, { FunctionComponent } from "react";
import { Dialog } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { FormTenantPromotionCodeSelect } from "@components/form/fields/FormTenantPromotionCodeSelect";
import { useFormContext } from "react-hook-form";
import { SubscriptionFormValues } from "../subscription-form/SubscriptionForm";
import { HttpError } from "@bps/http-client";

interface PromotionCodeConfirmationDialogProps {
  onDismiss: () => void;
  onSubmit: (values: SubscriptionFormValues) => Promise<void>;
  tenantId: string;
  error?: HttpError | null;
}

interface PromotionCodeConfirmationFormValues {
  promotionCodeList: string[] | undefined;
}
export const PromotionCodeConfirmationDialog: FunctionComponent<PromotionCodeConfirmationDialogProps> = ({
  onDismiss,
  onSubmit,
  tenantId,
  error
}) => {
  const { getValues } = useFormContext<SubscriptionFormValues>();
  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      minWidth={400}
      dialogContentProps={{
        title: "Do you want to select a discount code for the Subscription?",
        subText: "Click Cancel to go back or Select a discount code to proceed"
      }}
    >
      <Form<PromotionCodeConfirmationFormValues>
        defaultValues={{ promotionCodeList: undefined }}
        submitButtonText="Save"
        onSubmit={({ promotionCodeList }) => {
          const subscriptionFormValues = getValues();
          return onSubmit({
            ...subscriptionFormValues,
            promotionCodeList
          });
        }}
        cancelButtonText="Close"
        showCancelButton
        onCancel={() => onDismiss?.()}
        error={error}
      >
        <FormTenantPromotionCodeSelect
          required
          tenantId={tenantId}
          withNotSelectedOption
          name="promotionCodes"
        />
      </Form>
    </Dialog>
  );
};
