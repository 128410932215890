import { Form } from "@components/form/Form";
import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";

import { SubscriptionEditFormFields } from "./SubscriptionEditFormFields";
import { getAddSubscriptionsDtos, getSubscriptionsProducts } from "./utils";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import { PromotionCodeConfirmationDialog } from "../promotion-code-confirmation-dialog/PromotionCodeConfirmationDialog";
import {
  CustomerProductsDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useAddCustomerSubscriptions } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface SubscriptionFormProps {
  customerProducts: CustomerProductsDto;
  tenant: Tenant;
  id: string | undefined;
}

export interface SubscriptionFormValues {
  subscriptionProducts: SubscriptionItemProducts[];
  promotionCodeList?: string[];
}

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
  customerProducts,
  tenant,
  id
}) => {
  const [
    showMissedPromotionCodeDialog,
    setShowMissedPromotionCodeDialog
  ] = useState<boolean>(false);

  const {
    mutateAsync: submitSubscription,
    error
  } = useAddCustomerSubscriptions(tenant.id);

  const navigate = useNavigate();
  const subscription = customerProducts.subscriptionList.find(
    s => s.subscriptionId === id
  );

  const subscriptionProducts = subscription
    ? getSubscriptionsProducts(subscription).filter(s => !!s.quantity)
    : [];

  const handleSubmit = async (values: SubscriptionFormValues) => {
    const payload = getAddSubscriptionsDtos(values);

    await submitSubscription(payload);
    showMissedPromotionCodeDialog && setShowMissedPromotionCodeDialog(false);
    navigate(`../../../${tenant.id}/sales-subscriptions`);
  };

  const onTrySubmit = async (values: SubscriptionFormValues) => {
    // For a new subscription only, if a user has not picked a promotion code show a reminder popup
    if (!subscription && !values.promotionCodeList) {
      setShowMissedPromotionCodeDialog(true);
      return;
    }
    await handleSubmit(values);
  };

  return (
    <Form<SubscriptionFormValues>
      error={error}
      defaultValues={{
        subscriptionProducts,
        promotionCodeList: subscription?.promotionCodeList
      }}
      onSubmit={onTrySubmit}
      formStyles={{ root: { height: "100%", flexGrow: 1 } }}
      buttonsRootStyles={{ root: { marginTop: "auto" } }}
      hideButtons
    >
      <SubscriptionEditFormFields subscription={subscription} tenant={tenant} />
      {showMissedPromotionCodeDialog && (
        <PromotionCodeConfirmationDialog
          tenantId={tenant.id}
          onDismiss={() => setShowMissedPromotionCodeDialog(false)}
          onSubmit={handleSubmit}
          error={error}
        />
      )}
    </Form>
  );
};
