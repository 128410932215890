import React, { FunctionComponent } from "react";
import { UseFormReturn } from "react-hook-form";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

import { Grid, Separator, Stack } from "@bps/fluent-ui";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { Form } from "@components/form/Form";
import { SubmitFormButtons } from "@components/form/SubmitFormButtons";
import { SectionTitle } from "@components/SectionTitle";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  useAddTenantDefaultUserAuth,
  useUpdateTenantDefaultUserAuth
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";

import { IpRangeFormSection } from "../../_shared-blocks";
import {
  TenantUsersAuthFormValues,
  UsersAuthFormProps
} from "./TenantUsersAuthForm.types";

export const TenantUsersAuthForm: FunctionComponent<UsersAuthFormProps> = ({
  tenantInfo,
  tenantId
}) => {
  const {
    mutateAsync: addTenantDefaultUserAuth,
    error: errorAdd
  } = useAddTenantDefaultUserAuth();

  const {
    mutateAsync: updateTenantDefaultUserAuth,
    error: errorUpdate
  } = useUpdateTenantDefaultUserAuth();

  const defaultValues: TenantUsersAuthFormValues = {
    deviceMfaMaxDuration: tenantInfo?.deviceMfaMaxDuration
      ? tenantInfo.deviceMfaMaxDuration.toString()
      : undefined,
    sessionMaxDuration: tenantInfo?.sessionMaxDuration
      ? tenantInfo.sessionMaxDuration.toString()
      : undefined,
    quickPinRequired: !!tenantInfo?.quickPinRequired,
    longPasswordRequired: !!tenantInfo?.longPasswordRequired,
    requireAlternateEmailAuthenticationMethod: !!tenantInfo?.requireAlternateEmailAuthenticationMethod,
    requireAlternateEmailAuthenticationStrength: !!tenantInfo?.requireAlternateEmailAuthenticationStrength,
    requirePhoneAuthenticationMethod: !!tenantInfo?.requirePhoneAuthenticationMethod,
    requirePhoneAuthenticationStrength: !!tenantInfo?.requirePhoneAuthenticationStrength,
    requireTotpAuthenticationMethod: !!tenantInfo?.requireTotpAuthenticationMethod,
    requireTotpAuthenticationStrength: !!tenantInfo?.requireTotpAuthenticationStrength,
    ipRanges: tenantInfo?.ipRanges ?? []
  };

  const onSubmit = async (
    values: TenantUsersAuthFormValues,
    form: UseFormReturn
  ) => {
    const dto = {
      id: tenantId,
      deviceMfaMaxDuration: values.deviceMfaMaxDuration
        ? Number(values.deviceMfaMaxDuration)
        : undefined,
      sessionMaxDuration: values.sessionMaxDuration
        ? Number(values.sessionMaxDuration)
        : undefined,
      longPasswordRequired: values.longPasswordRequired,
      quickPinRequired: values.quickPinRequired,
      requireAlternateEmailAuthenticationMethod:
        values.requireAlternateEmailAuthenticationMethod,
      requireAlternateEmailAuthenticationStrength:
        values.requireAlternateEmailAuthenticationStrength,
      requirePhoneAuthenticationMethod: values.requirePhoneAuthenticationMethod,
      requirePhoneAuthenticationStrength:
        values.requirePhoneAuthenticationStrength,
      requireTotpAuthenticationMethod: values.requireTotpAuthenticationMethod,
      requireTotpAuthenticationStrength:
        values.requireTotpAuthenticationStrength,
      ipRanges: values.ipRanges
    };

    try {
      if (tenantInfo) {
        await updateTenantDefaultUserAuth(dto);
      } else {
        await addTenantDefaultUserAuth(dto);
      }
      form.reset(values);
    } catch (e) {
      return e;
    }
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserAuthenticationWrite
  );

  const rules: UseControllerProps["rules"] = {
    min: { value: 1, message: ValidationMessages.positiveNumber }
  };

  return (
    <Form<TenantUsersAuthFormValues>
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      hideSubmitButton={!hasWritePermission}
      formStyles={{ root: { maxWidth: 1170 } }}
      hideButtons
      error={errorAdd ?? errorUpdate}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Users Login Settings</SectionTitle>
        <SubmitFormButtons />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Grid
          templateColumns="repeat(2, 320px)"
          childrenTokens={{ columnGap: 16, rowGap: 8 }}
        >
          <FormSwitch
            label="User long password required"
            name="longPasswordRequired"
            disabled={!hasWritePermission}
          />
          <Stack />
          <FormSwitch
            label="User quick PIN required"
            name="quickPinRequired"
            disabled={!hasWritePermission}
          />
          <FormSpinNumberInput
            label="Pin Lifetime (days)"
            name="sessionMaxDuration"
            min={1}
            rules={rules}
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Setup Alternate Email MFA"
            name="requireAlternateEmailAuthenticationMethod"
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Enable Alternate Email MFA during login"
            name="requireAlternateEmailAuthenticationStrength"
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Setup Mobile Phone MFA"
            name="requirePhoneAuthenticationMethod"
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Enable Mobile Phone MFA during login"
            name="requirePhoneAuthenticationStrength"
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Setup Time based One Time Password (TOTP) MFA"
            name="requireTotpAuthenticationMethod"
            disabled={!hasWritePermission}
          />
          <FormSwitch
            label="Enable Time based One Time Password (TOTP) MFA during login"
            name="requireTotpAuthenticationStrength"
            disabled={!hasWritePermission}
          />
          <FormSpinNumberInput
            label="Device MFA max duration (min) (NOT USED)"
            name="deviceMfaMaxDuration"
            min={1}
            rules={rules}
            disabled={!hasWritePermission}
          />
        </Grid>
        <Separator vertical />
        <Stack.Item grow>
          <IpRangeFormSection hasWritePermission={!!hasWritePermission} />
        </Stack.Item>
      </Stack>
    </Form>
  );
};
