import { FunctionComponent, useState } from "react";
import { useWatch } from "react-hook-form";

import { DefaultButton, Dialog, Heading, Stack, Text } from "@bps/fluent-ui";
import { FormCheckbox } from "@components/form/fields/FormCheckbox";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormTimePicker } from "@components/form/fields/FormTimePicker";
import { Form } from "@components/form/Form";
import { useCustomerAccountQuery } from "@libs/api/gateways/cam/cam-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  AddStripeCustomerDto,
  Country,
  CountryText
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useAddStripeCustomer } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { config } from "@libs/config/config";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { EMAILREGEX } from "@libs/validation/validation-rules.constants";

interface CreateStripeAccountDialogProps {
  tenantId: string;
  country: Country;
  crmId: string;
}
export const CreateStripeAccountDialog: FunctionComponent<CreateStripeAccountDialogProps> = ({
  tenantId,
  country,
  crmId
}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const onDismiss = () => setHidden(true);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    [Permissions.PltCustomerWrite, Permissions.PltSalesWrite],
    "or"
  );

  const { mutateAsync: createStripeCustomer, error } = useAddStripeCustomer();

  const { data: customerAccount } = useCustomerAccountQuery(crmId || "");
  const today = new Date();
  const timeValue = today.toLocaleTimeString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit"
  });

  const isNonProd = config.env !== "prod";

  return (
    <>
      <DefaultButton
        disabled={!hasWritePermission}
        onClick={() => setHidden(false)}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Create Stripe Customer
      </DefaultButton>
      {!hidden && (
        <Dialog
          hidden={false}
          onDismiss={onDismiss}
          dialogContentProps={{
            title: "Create Stripe Customer",
            showCloseButton: true
          }}
        >
          <Form<Omit<AddStripeCustomerDto, "tenantId" | "country" | "crmId">>
            error={error}
            onCancel={onDismiss}
            defaultValues={{
              name: customerAccount?.businessName || "",
              email: customerAccount?.email1 || "",
              addressLine1: customerAccount?.addressLine1 || "",
              addressLine2: customerAccount?.addressLine2 || "",
              postcode: customerAccount?.postcode || "",
              city: customerAccount?.city || "",
              state: customerAccount?.state || "",
              useTestClock: false,
              testClockDate: today,
              testClockTime: timeValue
            }}
            allowSubmitOnClean={customerAccount !== undefined}
            onSubmit={async values => {
              await createStripeCustomer({
                tenantId,
                crmId,
                name: values.name,
                email: values.email,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                postcode: values.postcode,
                city: values.city,
                state: values.state,
                country,
                useTestClock: values.useTestClock,
                testClockDate: values.testClockDate,
                testClockTime: values.testClockTime
              });
            }}
            showCancelButton
          >
            <FormTextField
              name="name"
              label="Account name"
              rules={{ required: ValidationMessages.required }}
              required
            />
            <FormTextField
              name="email"
              label="Account email"
              rules={{
                required: ValidationMessages.required,
                pattern: {
                  value: EMAILREGEX,
                  message: ValidationMessages.email
                }
              }}
              required
            />
            <Heading labelPaddings>Address</Heading>
            <FormTextField name="addressLine1" placeholder="Line1" />
            <FormTextField name="addressLine2" placeholder="Line2" />
            <FormTextField name="city" placeholder="City" />
            <FormTextField name="state" placeholder="State" />
            <Stack
              horizontal
              tokens={{ childrenGap: 8 }}
              verticalAlign="center"
            >
              <FormTextField name="postcode" placeholder="Postcode" />
              <Text>{CountryText[country]}</Text>
            </Stack>
            {isNonProd && (
              <Stack tokens={{ childrenGap: 8 }} verticalAlign="center">
                <FormCheckbox name="useTestClock" label="Use Test Clock" />
                <FormUseTestClockWatcher />
              </Stack>
            )}
          </Form>
        </Dialog>
      )}
    </>
  );
};

const FormUseTestClockWatcher: FunctionComponent = () => {
  const useTestClockWatcher = useWatch({ name: "useTestClock" });

  return useTestClockWatcher ? (
    <Stack>
      <Text variant="small">Enter a Date and time to use</Text>
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        <FormDatePicker name="testClockDate" />
        <FormTimePicker name="testClockTime" />
      </Stack>
    </Stack>
  ) : (
    <></>
  );
};
